import React, { Component } from 'react';
import MobileSubHeader from '../../components/PageHeader/MobileSubHeader';
import MobileSelectRadio from '../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio';
// import X33MallModal from './X33MallModal';
import GamePagination from "../../components/Pagination/GamePagination";
import InputRange from 'react-input-range';
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import "react-input-range/lib/css/index.css";
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
import Button from '../../components/Button/Button';
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-awesome-reveal";

export class MobileMall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectRadio: false,
      topProfileData: [],
      faqOptions: [],
      options: [],
      // activeOption: '',
      // activeKey: 0,
      filterShow: false
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const faqOptions = [
      { icon: "all", label: t("global.all"), key: 0 },
      // { icon: "star", label: "Latest", key: 1 },
      { icon: "love", label: t("global.favorite"), key: 2 },
      // { icon: "price-high", label: "Price Low to High", key: 3 },
      // { icon: "price-low", label: "Price High to Low", key: 4 },
    ];

    const newOptions = faqOptions.filter((item) => item.key !== this.state.activeKey)

    this.setState({
      options: newOptions
    });
  }

  toggleSelectRadio = () => {
    this.setState({
      showSelectRadio: !this.state.showSelectRadio,
    });
    if (this.state.filterShow === false) {
      this.setState({
        filterShow: true,
      });
    } else if (this.state.filterShow === true) {
      this.setState({
        filterShow: false,
      });
    }
  };

  handleOptionChange = (event) => {
    // this.setState({
    //   activeOption: event.label,
    //   activeKey: event.key,
    // });

    return this.props.filterList(new MouseEvent('click'), event.label.toLowerCase(), true);
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    // const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

    const mbIcon = this.imageImport(
      require.context(
        "../../assets/images/profile/mobile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const img = this.imageImport(require.context("../../assets/images/x33mall/", false, /\.(png|jpe?g|svg)$/));

    const favIcon = this.imageImport(require.context("../../assets/images/icons/mobile", false, /\.(png|jpe?g|svg)$/));

    let topProfileData = null;
    if (!this.props.loading) {
      topProfileData = (this.props.topProfileData && this.props.topProfileData.data);
    }

    return (
      <Auxiliary>
        <section className="x33mall">
          <div className="x33mall-header">
            <MobileSubHeader
              // className="bg-white"
              returnPage="/me"
              header={t("mall.mall", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
              subPage='/redemption-history'
              subPageHeader="Records"
            />
            <div className="xmall-m-amount">
              <div className="row align-items-center">
                <div className="col-5ths"><img src={mbIcon["coin.png"]} alt="coin" /></div>
                <div className="col-9 p-0">
                  <div className="amount">
                    <div className="left">
                      <p>{t("profile-sidebar.coin", { attribute: process.env.REACT_APP_WEBSITE_TITLE }) + (" ")}<span className="number">{Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((this.props.userCoin))}</span>
                      </p>
                      <Icon icon="uil:angle-right"/>
                    </div>
                    <div className="right">
                      <a href="/mall/cart">
                        <img src={img["shopping-cart.svg"]} alt="shopping cart" />
                        <span className={`${this.props.cart_count > 0 ? 'notify-red-dot' : ''}`} />
                      </a>
                    </div>
                  </div>
                  <div className="earning">
                    <p>{t("profile-sidebar.today-earning") + (" ")}<span className="number">+ {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((topProfileData != null && topProfileData.topProfile.today_coin) || 0)}</span></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 mb-4">
              <div className="pf-fade-col">
                <div className="pd-card-col ">
                  <div className="x3m-m-filter">
                    <p>{t("mall.reward")}</p>
                    <button className={`btn btn-m-filter ${this.state.filterShow ? "active" : " "}`} type="button" onClick={this.toggleSelectRadio}>
                      <p>{this.props.filter === 'all' ? t("mall.all") : t("mall.favorite")} <Icon icon={`${this.state.filterShow ? "uil:angle-up" : "uil:angle-down"}`} /></p>
                      <MobileSelectRadio
                        name="PleaseSelect"
                        showSelectRadio={this.state.showSelectRadio}
                        toggleHandler={this.toggleSelectRadio}
                        onChange={this.handleOptionChange}
                        options={this.state.options}
                      />
                    </button>
                  </div>
                  <hr/>
                  <div className='filter-body'>
                    <div>
                    <small>{t("mall.filter", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</small>
                    <InputRange
                      maxValue={this.props.coinRange.max}
                      minValue={this.props.coinRange.min}
                      value={this.props.rangeInput}
                      onChange={rangeInput => {this.props.setRangeInput(rangeInput)}}
                      onChangeComplete={(rangeInput, type) => this.props.filterList(rangeInput, 'rangeInput')}
                      step="10"
                    />
                  </div>
                  <span className={this.props.filter === 'coinLowtoHigh' ? 'active' : ''} onClick={(e) => this.props.filterList(e, 'coinLowtoHigh')}><img src={img["price-high.svg"]} alt="Price high to low" /></span>
                  <span className={this.props.filter === 'coinHightoLow' ? 'active' : ''} onClick={(e) => this.props.filterList(e, 'coinHightoLow')}><img src={img["price-low.svg"]} alt="Price Low to High" /></span>

                  <Button
                      typeButton='button'
                      classValue='btn btn-success'
                      idValue='reset'
                      buttonName={t('global.reset')}
                      clicked={this.props.onResetHandler}
                  />
                  </div>
                  {
                    this.props.loading === true ? (
                      <Skeleton count={5} height={43.426} />
                    ) : (
                      this.props.products.length > 0 ? (
                        <Fade duration={1000}>
                          <div className="row gx-2">
                            {this.props.products && this.props.products.map((item, index) => (
                              <div className="col-4" key={index}>
                                <div className="product-body">
                                  <div className="product-cont" onClick={(e, item) => this.props.toggleProductModal(e, index)}>
                                    <p>{item.title}</p>
                                    <div className='ratio ratio-4x3'>
                                    <img src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + item.image} alt={item.title} />
                                    </div>
                                  </div>
                                  <div className="product-details">
                                    <p className="f-orange"><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{item.coin}</p>
                                    <button type="button" className="fav-btn" onClick={() => this.props.addFav(item.id)}>
                                      {this.props.favorites.length && this.props.favorites.includes(item.id) ? (
                                        <img src={favIcon["fav-red.png"]} className="fav-btn" alt="fav red"/>
                                      ) : (
                                        <img src={favIcon["fav-gray.png"]} className="fav-btn" alt="fav gray"/>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}

                            <GamePagination
                              totalPage={this.props.totalPage}
                              curPage={this.props.curPage}
                              data={this.props.products}
                              navigateList={(event, type, page)=> this.props.navigateList(event, type, page)}
                            />
                          </div>
                        </Fade>
                      ) : (
                        <div className="row gx-2">{t('norecord.norecords')}</div>
                      )
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileMall));