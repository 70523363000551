import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//Miscellaneous
import { Icon } from "@iconify/react";

import LuckySpin from "./LuckySpin";
import FifaWorldCup from "./FifaWorldCup";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import Button from '../../../components/Button/Button';
import DateScroller from '../../../components/MobileAsset/DateScroller/DateScroller';
import FIfaQna from './FIfaQna';
import Mall from './Mall';

const redemptionTypes = [
    { id: "typeLuckySpin", value: "lucky_spin", label: "lucky_spin" },
    // { id: "typeFifaWorldCup", value: "fifa_world_cup", label: "fifa_world_cup" },
    { id: "typeMall", value: "mall", label: "mall" },
    // { id: "typeFifaQna", value: "fifa_qna", label: "fifa_qna" },
];


class MobileRedemptionHistory extends Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            filterShow: '',
        }
    }

    imageImport = (r) =>
    {
        let images = {};
        r.keys().map((item, index) =>
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });

        return images;
    }

    showTypeHandler = () =>
    {
        this.setState({
            filterShow: 'type'
        })
    }

    showDateHandler = () =>
    {
        this.setState({
            filterShow: 'date'
        })
    }

    render()
    {
        const { t } = this.props;
        return (
            <Auxiliary>
                <DateScroller
                    onChangeDateHandler={this.props.onChangeDateHandler}
                    toggleShowDateSelect={this.props.toggleShowDateSelect}
                    {...this.state}
                    {...this.props}
                />
                <section className="pf-m-dashboard">
                    <div className={`filters-m-col ${this.state.filterShow ? 'show' : ''}`}>
                        <MobileSubHeader
                            // className="bg-white"
                            returnPage="/me"
                            header={t('profile-sidebar.redemption-history')}
                            style={{ justifyContent: "unset" }}
                        />
                        <div className="filters-m-panel">
                            <div className="filter-m-tp-col">
                                <div className="filter-m-cat">
                                    <label className="filter-m-label">{t('history.type')}:</label>
                                    <button className={`btn btn-m-filter ${this.state.filterShow === 'type' && 'active'}`} type="button" onClick={this.showTypeHandler}>
                                        <p>{t(`history.${this.props.historyTab}`)}</p>
                                        <Icon icon="uil:angle-down" />
                                    </button>
                                </div>
                                <div className="filter-m-cat">
                                    <label className="filter-m-label">{t('history.date')}:</label>
                                    <button className={`btn btn-m-filter date ${this.state.filterShow === 'date' && 'active'}`} type="button" onClick={this.showDateHandler}>
                                        <p>{ this.props.start_date } <br/> { this.props.end_date }</p>
                                        <Icon icon="uil:angle-down" />
                                    </button>
                                </div>
                            </div>
                            <div className="filter-m-btm-col">
                                {/* <div className={`filters-m-group ${this.state.filterShow === 'type' ? 'd-flex' : 'd-none'}`}>
                                {redemptionTypes &&
                                    redemptionTypes.map((item, index) => (
                                    <div className="form-check form-check-inline filter" key={index}>
                                        <input
                                            className="btn-check"
                                            type="radio"
                                            name="filterType"
                                            id={item.id}
                                            value={item.value}
                                            defaultChecked={index === this.props.defaultTab}
                                            checked={item.value === this.props.historyTab}
                                        />
                                        <label className="btn form-check-label" htmlFor={item.id} onClick={() => this.props.tabShow(item.value)}>
                                            {t(`history.${item.label}`)}
                                        </label>
                                    </div>
                                    ))}
                                </div> */}
                                <div className={`filters-m-date-group ${this.state.filterShow === 'date' ? 'd-block' : 'd-none'}`}>
                                <div className="d-flex align-items-center flex-wrap">
                                    {this.props.filterDate &&
                                    this.props.filterDate.map((item, index) => (
                                        <div className="form-check form-check-inline filter" key={index}>
                                            <input
                                                className="btn-check"
                                                type="radio"
                                                name="filterDate"
                                                id={item.id}
                                                value={item.value}
                                                defaultChecked={index === 0}
                                                checked={index === this.props.currentFilterDate}
                                                onClick={() => this.props.onChangeFilterDateHandler(index)}
                                            />
                                            <label className="btn form-check-label" htmlFor={item.id} onClick={
                                                () => [
                                                this.props.filterDayRange(item.value)
                                                ]
                                            }>
                                                {t(`history.${item.label}`)}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className="filter-m-date">
                                    <label className="filter-m-label">{t("history.start-date")}:</label>
                                    <button className="btn btn-m-filter-date" type="button" onClick={(e) => this.props.toggleShowDateSelect(e, 'startDate')}>
                                        <p>{this.props.start_date}</p>
                                        <Icon icon="uil:angle-right" />
                                    </button>
                                </div>
                                <div className="filter-m-date">
                                    <label className="filter-m-label">{t("history.end-date")}:</label>
                                    <button className="btn btn-m-filter-date" type="button" onClick={(e) => this.props.toggleShowDateSelect(e, 'endDate')}>
                                        <p>{this.props.end_date}</p>
                                        <Icon icon="uil:angle-right" />
                                    </button>
                                </div>
                                </div>
                                {this.state.filterShow &&
                                    <>
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-gradient-blue mt-3"
                                            idValue="search"
                                            buttonName={t('global.check')}
                                            clicked={() => {this.props.searchHandler(this.props.historyTab); this.setState({ filterShow: ''});}}
                                            disabled={this.props.disableSearchButton}
                                        />
                                        <Button
                                            typeButton='button'
                                            classValue='btn btn-success w-100'
                                            idValue='reset'
                                            buttonName={t('global.reset')}
                                            clicked={this.props.onResetHandler}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="overlay" onClick={() => this.setState({ filterShow: '' })} />
                    <div className="pd-m-card-col">
                        {this.props.historyTab === 'lucky_spin' ? (
                            <LuckySpin
                                tabName='lucky_spin'
                                data={this.props.lucky_spin}

                                {...this.state}
                                {...this.props}
                            />
                        ) : this.props.historyTab === 'fifa_world_cup' ? (
                            <FifaWorldCup
                                tabName='fifa_world_cup'
                                data={this.props.fifa_world_cup}

                                {...this.state}
                                {...this.props}
                            />
                        ): this.props.historyTab === 'fifa_qna' ? (
                            <FIfaQna
                                tabName='fifa_qna'
                                data={this.props.fifa_qna}

                                {...this.state}
                                {...this.props}
                            />
                        ) : this.props.historyTab === 'mall' ? (
                            <Mall
                                tabName='mall'
                                data={this.props.mall}

                                {...this.state}
                                {...this.props}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                </section>
            </Auxiliary>
        )
    }
}

export default (withNamespaces('translation')(MobileRedemptionHistory));
