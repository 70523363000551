// import { calculateSize } from "@iconify/react";
import React, { Component } from "react"
// import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from 'react-redux';
import GamePagination from "../../components/Pagination/GamePagination";
import InputRange from 'react-input-range';
import Skeleton from "react-loading-skeleton";
import { Fade, Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import MallModal from "./MallModal";
import MobileMall from "./MobileMall";
import { isDesktop } from "react-device-detect";
import Swal from "sweetalert2";
import { list, favorite_list, favorite, cart_add } from "../../store/ducks/X33Mall/actions";
import { getTopProfile } from "../../store/ducks/profile/actions";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import "react-input-range/lib/css/index.css";
// import Modal from "react-modal";
import ProfileSideBar from "../Profile/ProfileSideBar/ProfileSideBar";
import { Icon } from "@iconify/react";

// const products = [
// 	{ id: 1, name: "iphone 13 128GB", image: "iphone-pink.png", points: "10,000", fav: true, new: true },
// 	{ id: 2, name: "Apple Watch s6", image: "apple-watch.png", points: "8,000", fav: false, new: true },
// 	{ id: 3, name: "Beats Studio3 Wireless", image: "b-headphone.png", points: "8,000", fav: false, new: true },
// 	{ id: 4, name: "iphone 13 128GB", image: "iphone-pink.png", points: "10,000", fav: false, new: true },
// 	{ id: 5, name: "Apple Watch s6", image: "apple-watch.png", points: "8,000", fav: false, new: true },
// 	{ id: 6, name: "Beats Studio3 Wireless", image: "b-headphone.png", points: "8,000", fav: false, new: true },
// ];

export class Mall extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initiated: false,
			coinRange: { min: 0, max: 0 },
			rangeInput: { min: 0, max: 0 },
			// favLove: false,
			userCoin: 0,
			products: [],
			productModal : false,
			url: '',
			filter: 'all',
			sort: '',
			curPage: 1,
			totalPage: 0,
			redeemModal: false,
			loading: true,
			showProduct: false,
			favorites: [],
			qty: 1,
			total: 0,
			cart_count: 0,
			spin: false,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.props.list({
			lang: this.props.currentUser.language_id,
			limit: 30
		});

		this.props.favorite_list();

		if (this.props.topProfileData === undefined) {
			if (localStorage.getItem("accessToken")) {
				this.props.getTopProfile();
			}
		}
		else {
			this.setState({ loading: false });
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const { t } = this.props;

		if (nextProps.reward_data !== this.props.reward_data) {
			if (nextProps.reward_data.responseCode === 200) {
				if ( !this.state.initiated) {
					this.setState({
						initiated: true,
						rangeInput: {
							min: Number(nextProps.reward_data.data.result.row[0]?.min_coin) || 0,
							max: Number(nextProps.reward_data.data.result.row[0]?.max_coin) || 0
						},
					});
				}

				this.setState({
					userCoin: nextProps.reward_data.data.coin,
					products: nextProps.reward_data.data.result.row,
					coinRange: {
						min: Number(nextProps.reward_data.data.result.row[0]?.min_coin) || 0,
						max: Number(nextProps.reward_data.data.result.row[0]?.max_coin) || 0
					},
					cart_count: nextProps.reward_data.data.cart_count,
					curPage: nextProps.reward_data.data.result.pagination.current_page,
					totalPage: nextProps.reward_data.data.result.pagination.total_page,
					loading: false
				});
			}
		}

		if (nextProps.cart_add_data !== this.props.cart_add_data && nextProps.cart_add_data !== undefined) {
			if (nextProps.cart_add_data.responseCode === 200) {
				this.setState({
					cart_count: this.state.cart_count + nextProps.cart_add_data.data.qty
				});

				Swal.fire({
					iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
					icon: 'success',
					title: t('global.mall_success'),
					confirmButtonText: t('global.ok'),
					customClass: (!isDesktop) ? {
						container: 'swal-mobile-container sucess',
						icon: 'swal-success-icon'
					}: [],
				});
			}
		}

		if (nextProps.favorite_data !== this.props.favorite_data) {
			if (nextProps.favorite_data.responseCode === 200) {
				this.setState({
					favorites: nextProps.favorite_data.data.result
				});
			}
		}

		return true;
	}

	addFav = (id) => {
		let newFavorite = [...this.state.favorites];

		if (newFavorite.includes(id)) {
			newFavorite = newFavorite.filter(item => item !== id)
		}
		else {
			newFavorite.push(id);
		}

		this.setState({
			favorites: newFavorite
		});

		this.props.favorite({
			item_id: id
		});
	}

	minusProductCount = () => {
		if (this.state.qty > 1) {
			this.setState({
				qty: this.state.qty - 1,
				total: (this.state.showProduct?.coin * (this.state.qty - 1)).toFixed(2)
			});
		}
	}

	addProductCount = () => {
		let newQty = this.state.qty;
		newQty += 1;

		if (newQty > this.state.showProduct?.stock) {
			return false;
		}

		if (this.state.showProduct?.limit !== 0 && newQty > this.state.showProduct?.limit) {
			return false;
		}

		this.setState({
			qty: this.state.qty + 1,
			total: (this.state.showProduct?.coin * (this.state.qty + 1)).toFixed(2)
		});
	}

	toggleProductModal = (e, index) => {
		this.setState({
			productModal: !this.state.productModal,
			showProduct: (index !== undefined) ? this.state.products[index] : false,
			total: (index !== undefined) ? this.state.products[index]['coin'] : 0,
			qty: 1
		});
	}

	toggleRedeemModal = () => {
		this.setState({
			redeemModal: !this.state.redeemModal,
			productModal: false,
		})
	}

	redeem = (id, qty) => {
		const { t } = this.props;

		this.props.cart_add({
			item: id,
			quantity: qty
		});

		Swal.fire({
			html: t('referral.processing'),
			didOpen: () => {
				Swal.showLoading()
			},
			customClass: (!isDesktop) ? {
				container: 'swal-mobile-container sucess',
				// icon: 'swal-success-icon'
			}: [],
		})
		.then((result) => {
		});

		this.toggleProductModal();
	}

	handleChange(event) {
		this.setState({ url: event.target.value });
	}

	setRangeInput = (rangeInput) => {
		this.setState({
			rangeInput: rangeInput
		});
	}

	imageImport = (r) => {
		let images = {};
		r.keys().map((item, index) => {
			return (images[item.replace("./", "")] = r(item)["default"]);
		});

		return images;
	};

	filterList = (event, type, reset = false) => {
		if (type !== 'rangeInput') {
			event.preventDefault();
		}

		let params = {};
		params.lang = this.props.currentUser.language_id;
		params.limit = 30;

		switch (type) {
			case 'all':
				params.filter = 'all';
				break;
			case 'favorite':
				params.filter = 'favorite';
				break;
			default:
				break;
		}

		if (type !== 'rangeInput') {
			if( type === "all" || type === "latest" || type === "favorite") {
				this.setState({
					filter: type
				});

				params.filter = type;
			}
			else {
				params.filter = this.state.filter;
			}
		}

		if (['coinLowtoHigh', 'coinHightoLow'].indexOf(type) > -1) {
			params.sort = type;
		}

		if (type === 'rangeInput') {
			params.lowest_coin = event.min;
			params.highest_coin = event.max;
			params.filter = this.state.filter;
		}

		if (reset) {
			this.setState({ initiated: false });
		}

		this.setState({ loading: true });
		this.props.list(params);
	}

	navigateList = (event, type, customPage = 0) => {
		event.preventDefault();

		let params = {};
		params.lang = this.props.currentUser.language_id;
		params.limit = 30;
		// params.fav = this.state.fav;
		// params.category = this.state.category;
		// params.search = this.state.search;

		if (localStorage.getItem('accessToken')){
			params.buffer = this.props.currentUser.id;
		}

		switch (type) {
			case 'page':
				params.page = customPage === 0 ? this.state.curPage : customPage;
				break;
			case 'prev':
				params.page = this.state.curPage !== 1 ? this.state.curPage - 1 : this.state.curPage;
				break;
			case 'next':
				params.page = this.state.curPage < this.state.totalPage ? this.state.curPage + 1 : this.state.curPage;
				break;
			default:
				params.page = this.state.curPage;
				break;
		}

		this.setState({ loading: true });
		this.props.list(params);
  }

	onResetHandler = () =>
	{
		this.setState({
			rangeInput: { min: this.state.coinRange.min, max: this.state.coinRange.max },
			filter: 'all',
			spin: true,
			loading: true,
		});

		this.props.list({
			lang: this.props.currentUser.language_id,
			limit: 30
		});
	}

	render() {
		const img = this.imageImport(require.context("../../assets/images/x33mall/", false, /\.(png|jpe?g|svg)$/));
		const favIcon = this.imageImport(require.context("../../assets/images/icons/mobile", false, /\.(png|jpe?g|svg)$/));
		const resetIcon = this.imageImport(require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)	);
		const { t } = this.props;

			const fadeUp = keyframes`
			from {
			opacity: 0;
			transform: translate3d(0, 50px ,0);
			}

			to {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			}
	`;

	let imageLanguage = "";
	switch (this.props.currentUser.language_code) {
			case "zh-CN":
					imageLanguage = "-cn";
					break;
			case "ms":
			    imageLanguage = "-ms"
			    break;
			case "id":
			    imageLanguage = "-id"
			    break;
			default:
					imageLanguage = "";
					break;
	}

		return (
			<Auxiliary>
				{
					isDesktop ? (
						<>
							<section className="x33mall">
								<div className="pf-dashboard ">
									<div className="x-container">
											<div className="ps-col">
												<ProfileSideBar/>
											</div>
											<Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
											<div className="row g-3">
												<div className="col-12">
													<div className="pd-card-col p-0">
														<img  src={img[`x33mall-banner${imageLanguage}.jpg`]} alt="R77mall top online shopping mall" className="x33mall-banner"/>
													</div>
													<div className="pd-card-col h-auto">
														<div className="pd-content">
															<div className="filters-col">
																<div className="fd-col">
																	<ul className="nav nav-tabs">
																	<li className="nav-item">
																		<a href="/#" className={`nav-link ${this.state.filter === 'all' ? 'active' : ''}`} role="button" onClick={(e) => this.filterList(e, 'all', true)}>
																		<p><img src={img["all.svg"]} alt="filter all" />{t("mall.all")}</p>
																		</a>
																	</li>
																	{/* <li className="nav-item">
																		<a className={`nav-link ${this.state.filter === 'latest' ? 'active' : ''}`} role="button" onClick={(e) => this.filterList(e, 'latest')}>
																		<p><img src={img["star.svg"]} alt="Latest" />{t("mall.latest")}</p>
																		</a>
																	</li> */}
																	<li className="nav-item">
																		<a href="/#" className={`nav-link ${this.state.filter === 'favorite' ? 'active' : ''}`} role="button" onClick={(e) => this.filterList(e, 'favorite',)}>
																		<p><img src={img["love.svg"]} alt="Favorite" />{t("mall.favorite")}</p>
																		</a>
																	</li>
																	</ul>
																	<div className="filter-body">
																		<div className="filter-content">
																			<h6>{t("mall.filter",{ attribute: process.env.REACT_APP_WEBSITE_TITLE })}</h6>
																			<InputRange
																				maxValue={this.state.coinRange.max}
																				minValue={this.state.coinRange.min}
																				value={this.state.rangeInput}
																				onChange={(rangeInput) => {this.setRangeInput(rangeInput)}}
																				onChangeComplete={(rangeInput, type) => this.filterList(rangeInput, 'rangeInput')}
																				step="10"
																			/>
																		</div>
																		{/* <button type="button" className="btn btn-gradient-blue">Apply</button> */}
																		<div>
																		<button type="button" className={`btn btn-outline-blue me-2 ${this.state.filter === 'coinLowtoHigh' ? 'active' : ''}`} onClick={(e) => this.filterList(e, 'coinLowtoHigh')}><img src={img["price-high.svg"]} alt="Price Low to High" />{t("mall.price-low")}</button>
																		<button type="button" className={`btn btn-outline-blue ${this.state.filter === 'coinHightoLow' ? 'active' : ''}`} onClick={(e) => this.filterList(e, 'coinHightoLow')}><img src={img["price-low.svg"]} alt="Price High to Low" />{t("mall.price-high")}</button>
																		<span className="reset-btn"><img className={`${this.state.spin ? "spin" : ""} `} src={resetIcon["rotate-icon.svg"]} alt="reset" onClick={this.onResetHandler} /></span>
																		</div>
																		{/* <span className="nav-item">
																			<a className={`nav-link ${this.state.filter === 'coinLowtoHigh' ? 'active' : ''}`} role="button" onClick={(e) => this.filterList(e, 'coinLowtoHigh')}>
																			<p><img src={img["price-high.svg"]} alt="Price Low to High" />Price Low to High</p>
																			</a>
																		</span>
																		<span className="nav-item">
																			<a className={`nav-link ${this.state.filter === 'coinHightoLow' ? 'active' : ''}`} role="button" onClick={(e) => this.filterList(e, 'coinHightoLow')}>
																			<p><img src={img["price-low.svg"]} alt="Price High to Low" />Price High to Low</p>
																			</a>
																		</span> */}
																	</div>
															</div>
														</div>
														</div>
													</div>

													<div className="pd-card-col ">
														<div className="product-title">
														<h4>{t("mall.reward")}</h4>
														<p>{t('mall.my-carts')}
														<Link to={{ pathname: "/mall/cart" }}>
															<Icon type="button" icon="mdi:cart" />
															</Link>
															<span className="notify-red-dot">
															<small>{this.state.cart_count}</small>
															</span>

															</p>
														</div>
														{
															this.state.loading === true ? (
																<Skeleton count={5} height={75.866} />
															) : (
																this.state.products.length > 0 ? (
																	<Fade duration={1000}>
																		<div className="row g-2">
																			{this.state.products && this.state.products.map((item, index) => (
																				<div className="col-5ths" key={index}>
																					<div className="product-body">
																						<div className="product-cont" onClick={(e, item) => this.toggleProductModal(e, index)}>
																							<p>{item.title}</p>
																							<div className="ratio ratio-4x3">
																							<img src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + item.image} alt={item.title} />
																							</div>
																						</div>
																						<div className="product-details">
																							<p className="f-orange">
																								<img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{item.coin}
																							</p>
																							<button type="button" className="fav-btn" onClick={() => this.addFav(item.id)}>
																								{this.state.favorites.length && this.state.favorites.includes(item.id) ? (
																									<img src={favIcon["fav-red.png"]} className="fav-btn" alt="fav red"/>
																								) : (
																									<img src={favIcon["fav-gray.png"]} className="fav-btn" alt="fav gray"/>
																								)}
																							</button>
																						</div>
																					</div>
																				</div>
																			))}
																		</div>

																		<GamePagination
																			totalPage={this.state.totalPage}
																			curPage={this.state.curPage}
																			data={this.state.products}
																			navigateList={(event, type, page)=> this.navigateList(event, type, page)}
																		/>
																	</Fade>
																) : (
																	<div className="row g-2">{t('norecord.norecords')}</div>
																)
															)
														}
													</div>
												</div>
												</div>
												</Reveal>
											</div>
										</div>
							</section>
						</>
					) : (
						<MobileMall
							{...this.state}
							{...this.props}
							addFav={this.addFav}
							minusProductCount={this.minusProductCount}
							addProductCount={this.addProductCount}
							toggleProductModal ={this.toggleProductModal}
							setRangeInput={this.setRangeInput}
							navigateList ={this.navigateList}
							filterList={this.filterList}
							onResetHandler={this.onResetHandler}
						/>
					)
				}
				<MallModal
					{...this.state}
					{...this.props}
					toggleProductModal={this.toggleProductModal}
					toggleRedeemModal={this.toggleRedeemModal }
					minusProductCount={this.minusProductCount}
					addProductCount={this.addProductCount}
					addFav={this.addFav}
					redeem={this.redeem}
				/>
			</Auxiliary>
		)
	}
}

const mapStateToProps = state => {
	const { x33Mall } = state;

	return {
		currentUser: state.currentUser.data,
		reward_data: x33Mall.reward_data,
		favorite_data: x33Mall.favorite_data,
		cart_add_data: x33Mall.cart_add,
		topProfileData: state.profile.top_profile_data
	};
};

const mapDispatchToProps = {
	list,
	favorite_list,
	favorite,
	cart_add,
	getTopProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Mall));
